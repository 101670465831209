<template>
  <div>
    <div style="display: flex;">
      <el-input v-model="searchText" size="large" placeholder="Поиск по имени" :loading="loading" style="width: 20em;" class="mb4" @keydown.enter="getUsersList(); pageNumber=1">
        <template #prefix>
          <el-icon>
            <Search />
          </el-icon>
        </template>
      </el-input>
      <el-button size="large" type="primary" class="ml3" @click="getUsersList(); pageNumber=1">
        <el-icon>
          <Search />
        </el-icon>
      </el-button>
    </div>
    <el-table style="width:100%" :data="items" :default-sort="{ prop: 'position', order: 'descending' }">
      <el-table-column prop="nameAndEmail" label="Текущее имя и почта" width="200px" />
      <el-table-column prop="action" label="Новое имя">
        <template #default="scope">
          <UsersChangeField type="name" :item="scope.row" :rates="rates" @get-users-list="getUsersList" />
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Новый пароль">
        <template #default="scope">
          <UsersChangeField type="pass" :item="scope.row" :rates="rates" @get-users-list="getUsersList" />
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Заблокирован" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <div style="border:1px rgba(255,255,255,0.3) solid; width:1.9444444444444444em; height:1.9444444444444444em; border-radius: 0.5555555555555556em; display: flex; align-items: center; justify-content: center" @click="banUser(scope.row)">
              <el-icon v-if="scope.row.isBanned" :size="27">
                <CloseBold />
              </el-icon>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-size="pageSize"
      :page-count="totalPages"
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ElMessage,
} from 'element-plus';
import UsersChangeField from '../users/UsersChangeField.vue';

export default {
  components: {
    UsersChangeField,
  },
  name: 'FinanceRequestTable',
  // props: {
  //   items: Array,
  // },
  data: () => ({
    searchText: '',
    items: [],
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    pageNumber: 1,
    rates: [],
  }),
  mounted() {
    this.getUsersList();
  },
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    async banUser(user) {
      console.log(user.id, user.isBanned);
      try {
        await this.$axios.post(`user/${user.isBanned ? 'unban-user' : 'ban-user'}?userId=${user.id}`);
        ElMessage({ message: `Юзер успешно ${user.isBanned ? 'разблокирован' : 'заблокирован'}`, type: 'success' });
        this.getUsersList();
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
    pageChange(page) {
      this.pageNumber = page;
      this.getUsersList();
    },
    async getUsersList() {
      let query = '';
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.searchText) {
        query = `?pseudonym=${this.searchText}`;
      }
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/user/search-user-by-pseudonym${query}`, params);
        this.totalPages = data.totalPages;
        this.totalCount = data.totalCount;
        this.items = data.items.map((v) => {
          v.nameAndEmail = `${v.pseudonym} - ${v.email}`;
          return v;
        });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
